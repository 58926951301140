<template>
  <quote-config :show-overrides="false" />
</template>

<script>
import QuoteConfig from '@/components/QuoteConfig.vue'
export default {
  components: {
    QuoteConfig
  }
}
</script>

<style scoped></style>
