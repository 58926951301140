<template>
  <section id="ROICalc">
    <div class="container">
      <div v-show="showOverrides" class="show-overrides">
        <h2>Configure Quote</h2>
        <pac-columns>
          <div class="column" />
          <div class="column">
            <h3>General</h3>
            <br>
            <label for="perShipmentCost">Avg. Per Shipment Cost (dollars)</label><br>
            <input v-model.number="perShipmentCost"><br><br>

            <label for="orderVol">Monthly Orders </label><br>
            <input v-model.number="orderVol"><br><br>

            <label for="multiVol">Multi-Line Orders (percentage)</label><br>
            <input v-model.number="multiVol" :min="0" :max="100">%<br><br>

          </div>
          <div class="column">
            <h3>Pricing</h3>
            <br>
            <input v-model="perTcostOverride" type="checkbox">
            <label for="perTcostOverride">Enable Pricing Overrides</label><br>

            <label for="perTcost">Per Transaction Cost </label><br>
            <input v-model.number="perTcost" :disabled="!perTcostOverride"><br><br>

            <label for="floorPrice">Floor Price </label> (minimum monthly charge)<br>
            <input v-model.number="floorPrice" :disabled="!perTcostOverride"><br><br>

            <label for="includedTransactions">Included Transactions </label><br>
            <input v-model.number="includedTransactions" :disabled="!perTcostOverride"><br><br>

            <label for="costAwareSavings">% Cost-Aware Savings </label></br>
            <input v-model.number="costAwareSavings" :min="0" :max="100" :disabled="!perTcostOverride"><br><br>

            <label for="errorSavings">% Error Savings </label><br>
            <input v-model.number="errorSavings" :min="0" :max="100" :disabled="!perTcostOverride"><br><br>
          </div>
          <div class="column" />
        </pac-columns>
        <div style="background: #f1f1f1; padding: 1em; max-width: 500px; margin: 0px auto; margin-top: 1em; margin-bottom: 2em;text-align: center;"><h3>Quote Link (right click, copy link):</h3><a :href="'/?drate=' + configString">/?drate={{ configString }}</a></div>
        <br><br><br><br><br>
      </div>
      <h2 class="bigTitle">
        Calculate ROI
      </h2>
      <div id="simpleROI" class="has-text-centered">
        <pac-columns>
          <div class="column is-two-thirds sliderCol">
            <client-only>
              <div :style="{ 'backgroundPosition': fancyBG}" class="order-vol-slider">
                <label for="orderVol">How many orders do you fulfill per month, via parcel?</label>
                <vue-slider
                  v-model="orderVol"
                  :min="100"
                  :max="100000"
                  :interval="100"
                  :marks="volMarks"
                  :tooltip-formatter="tooltipFormatter"
                />
              </div>
            </client-only>
          </div>
          <div class="column is-one-sixth roiEstimate">
            <div class="estimateBlock">
              <div class="has-text-centered">Estimated Cost / Month</div>
              <div class="simpleCostEstimate">{{ estPrice | moneyMaker }}</div>
            </div>
            <div class="estimateBlock">
              <div class="has-text-centered priceHeader">Est. Net Parcel Savings / Month</div>
              <div class="simpleEstSavings">{{ estSavings | moneyMaker }}+</div>
            </div>
          </div>
        </pac-columns>
      </div>
      <div id="advancedROI">
        <pac-columns>
          <div class="column">
            <h2 style="margin-bottom: 1em;">Advanced Controls</h2>
            <label for="multiVol">Monthly multi-item <span data-tooltip="What percentage of your outgoing orders have more than 1 item?"><font-awesome-icon icon="question-circle" /></span></label>
            <vue-slider v-model="multiVol" :marks="multiPerc" :tooltip-formatter="tooltipFormatter2" />
            <br><br>
            <label for="multiVol">Avg Per-Shipment Spend <span data-tooltip="How much does shipping cost for each order, on average?"><font-awesome-icon icon="question-circle" /></span></label>
            <vue-slider
              v-model="perShipmentCost"
              :min="5"
              :max="100"
              :interval="5"
              :marks="multiMoney"
              :tooltip-formatter="tooltipFormatter1"
            />
            <br><br><br><br>
            <h2 style="text-align: left;">Prove It</h2>
            <pac-columns class="subcol">
              <div class="column"><p>
                This estimator is based on historical savings in successful
                analyses and implementations. Your milage may vary.
              </p></div>
              <div class="column"><p>
                For a more accurate ROI, ask us about an analysis of your shipping history.
              </p></div>
            </pac-columns>

            <!-- <h3>Savings Assumptions</h3>
            <label for="errorSavings">Savings from general error reduction</label>
            <vue-slider v-model="errorSavings" :marks="multiPerc" :max="50" :tooltip-formatter="tooltipFormatter2" />
            <br> -->
            <!-- <label for="costAwareSavings">Savings from "costs" awareness</label>
            <vue-slider v-model="costAwareSavings" :marks="multiPerc" :max="50" :tooltip-formatter="tooltipFormatter2" /> -->
          </div>
          <div class="column is-four-sixths">
            <div id="envelopeMath">
              <h3 style="margin-top: 0px;">One month, for example</h3>
              <table>
                <tbody>
                  <tr>
                    <td>Total Shipping Spend <br> <span>({{ orderVol | numberMaker }} orders &times; {{ perShipmentCost | moneyMaker }} per order)</span></td>
                    <td class="costFactor">{{ monthlyShipSpend | moneyMaker }}</td>
                  </tr>
                  <tr>
                    <td>Cartonization Cost <br><span>({{ floorPrice | moneyMaker }} + ({{ orderVol | numberMaker }} orders - {{ floorIncludedTransactions | numberMaker }} included) &times; {{ perTcost | moneyMaker }})</span></td>
                    <td class="costFactor">+ {{ estPrice | moneyMaker }}</td>
                  </tr>
                  <tr>
                    <td>Savings from Error Reduction <br> <span>({{ errorSavings }}% &times; {{ orderVol | numberMaker }} orders &times; {{ perShipmentCost | moneyMaker }} per order)</span> </td>
                    <td class="saveFactor">- {{ errorTotal | moneyMaker }}</td>
                  </tr>
                  <tr>
                    <td>Savings from Rate Awareness <br> <span>({{ costAwareSavings }}% &times; {{ (orderVol * multiVol * 0.01).toFixed(0) | numberMaker }} multi-line orders &times; {{ perShipmentCost | moneyMaker }} per order)</span> </td>
                    <td class="saveFactor">- {{ costAwareTotal | moneyMaker }}</td>
                  </tr>
                  <tr id="netSavings">
                    <td>Net Monthly Savings</td>
                    <td class="saveFactor totalSave">{{ estSavings | moneyMaker }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </pac-columns>
        <div id="breakItDown">
          <h2 style="margin-left: 1em; text-align: left;">Break It Down</h2>
          <pac-columns>
            <div class="column"><strong>Error Reduction:</strong> (9% - 25%) Computers are better at packing, and don't make mistakes like even very experienced humans do. Taking the guesswork away can supercharge even seasonal packers.</div>
            <div class="column"><strong>Cost Awareness:</strong> (Conservatively 6%) One of the unique core features of our cartonization engine is its ability to consider costs when making a packing decision. This might be box cost, labor cost, or even the complex incentive patterns in your negotiated rate tables.</div>
            <div class="column"><strong>Time/Labor Savings:</strong> Faster packing means more throughput. Time saved is hard to generalize and is outside the scope of this calculator.</div>
          </pac-columns>
        </div>
      </div>
    </div>
    </div></section>
</template>

<script>

import 'vue-slider-component/theme/antd.css'
import { compressToBase64, decompressFromBase64 } from 'lz-string'
const CONFIGURABLE_FIELDS = [
  'perShipmentCost',
  'orderVol',
  'multiVol',
  'perTcost',
  'floorPrice',
  'includedTransactions',
  'costAwareSavings',
  'errorSavings'
]
export default {
  filters: {
    moneyMaker (val) {
      if (!val) {
        val = 0
      }
      if (Number(val.toFixed(2)) === Number(val.toFixed(3))) {
        val = val.toFixed(2)
      } else {
        val = val.toFixed(3)
      }
      return '$' + val.replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    numberMaker (val) {
      if (!val) {
        val = 0
      }
      return String(val).replace(/\d(?=(\d{3})+$)/g, '$&,')
    }
  },
  props: {
    showOverrides: Boolean
  },
  // TODO: make data grate again
  //       also have an optional big object parameter passed to data
  //       that is merged with the defaults and overrides selectively.
  // 'orderVol',
  // 'multiVol',
  // 'perTcostOverride'
  // 'perTcost',
  // 'floorPrice',
  // 'costAwareSavings',
  // 'errorSavings',
  data () {
    const qConfig = {
    //  perShipmentCost: 15.75,
    //  orderVol: 25325,
    //  multiVol: 33.3,
    //  perTcostOverride: true,
    //  perTcost: 0.02,
    //  floorPrice: 500.00,
    //  includedTransactions: 25000,
    //  costAwareSavings: 5.5,
    //  errorSavings: 2.5
    }

    return { ...{
      seen: false,
      orderVol: 100, //         n orders total
      multiVol: 25, //          % of n that are multi-line orders
      perShipmentCost: 10, //   clams
      monthlyShipSpend: 0, //   updated to whatever the calculated value is.
      estSavings: 0, //         "
      estPrice: 0, //           "
      perTcostOverride: false,
      perTcost: 0.05,
      // TODO: add dynamic definition of pricing tiers
      //       and refactor price-per-request to read from
      //       dynamic definition
      // tiers: [
      //   { min: 100...}
      costAwareSavings: 6,
      costAwareTotal: 0,
      errorSavings: 10,
      errorTotal: 0,
      fancyBG: '0% 79%',
      floorPrice: null,
      includedTransactions: null,
      floorIncludedTransactions: 0,
      volMarks: {
        '100': '100',
        '10000': '10k',
        '25000': '25k',
        '50000': '50k',
        '75000': '75k',
        '100000': '100k'
      },
      tooltipFormatter: v => `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      tooltipFormatter1: v => `$${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      tooltipFormatter2: v => `${('' + v)}%`,
      multiPerc: val => val % 10 === 0 ? ({
        label: `${val}%`,
        labelStyle: {
          opacity: val * 0.01 * 0.7 + 0.3
        },
        labelActiveStyle: {
          color: '#3498db'
        }
      }) : false,
      multiMoney: val => val % 10 === 0 ? ({
        label: `$${val}`
      }) : false
    },
    ...qConfig }
  },
  computed: {
    changeData () {
      const { orderVol, multiVol, perShipmentCost, errorSavings, costAwareSavings } = this
      return {
        orderVol,
        multiVol,
        perShipmentCost,
        errorSavings,
        costAwareSavings
      }
    },
    changeBG () {
      const { orderVol } = this
      return {
        orderVol
      }
    },
    configString () {
      const self = this
      let res = []
      CONFIGURABLE_FIELDS.forEach(function (field) {
        res.push(self[field])
      })
      res = res.join(',')
      // res = res + ';' + compressToBase64(res)
      return compressToBase64(res)
    }
  },
  watch: {
    changeData: {
      handler (val) {
        this.calcSavings()
      },
      deep: true
    },
    changeBG: {
      handler (val) {
        const newColor = ((val.orderVol / 100000) * 100).toFixed().toString()
        this.fancyBG = newColor + '% 50%'
      }
    }
  },
  mounted () {
    this.calcSavings()
    if (this.$route.query.drate) {
      this.configFromString(this.$route.query.drate)
    }
  },
  methods: {
    configFromString (sB64) {
      const self = this
      this.perTcostOverride = true
      const s = decompressFromBase64(sB64)
      s.split(',').forEach(function (field, i) {
        let casted = Number(field)
        if (Number.isNaN(casted)) {
          casted = field
        }
        self[CONFIGURABLE_FIELDS[i]] = casted
      })
    },
    calcSavings () {
      this.monthlyShipSpend = this.orderVol * this.perShipmentCost
      this.pricePerRequest(this.orderVol)
      this.errorTotal = (this.errorSavings / 100) * this.monthlyShipSpend
      this.costAwareTotal = (this.costAwareSavings / 100) * (this.monthlyShipSpend * (this.multiVol / 100))
      this.estSavings = (this.errorTotal + this.costAwareTotal) - this.estPrice
    },
    pricePerRequest (val) {
      if (!this.perTcostOverride) {
        // console.log(val)

        if (val >= 1000000) {
          this.perTcost = 0.018
          this.floorPrice = 21110
          this.includedTransactions = 1000000
        } else if (val >= 100000 && val <= 999999) {
          this.perTcost = 0.02
          this.floorPrice = 3110
          this.includedTransactions = 100000
        } else if (val >= 10000 && val <= 99999) {
          this.perTcost = 0.03
          this.floorPrice = 410
          this.includedTransactions = 10000
        } else if (val >= 1000 && val <= 9999) {
          this.perTcost = 0.04
          this.floorPrice = 50
          this.includedTransactions = 1000
        } else if (val >= 0 && val <= 999) {
          this.perTcost = 0.05
          this.floorPrice = 25
          this.includedTransactions = 500
        }
      } else {
        // if we had an override,
        if (this.floorPrice == null) {
          this.floorPrice = 0
        }
        if (this.includedTransactions == null) {
          this.includedTransactions = 0
        }
      }
      this.floorIncludedTransactions = Math.min(val, this.includedTransactions)
      this.estPrice = this.floorPrice + this.perTcost * (val - this.floorIncludedTransactions)
    }
  }
}
</script>

<style scoped>
#showAdvanced{
  cursor: pointer;
}
.fa-question-circle{
  color: #999;
  font-size: 0.85rem;
  margin-left: 0.4rem;
}

.vue-slider{
  margin-top: 0.8rem;
  margin-bottom: 0.5rem;
}

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
</style>
